import request from '@/utils/request'


// 查询特权卡列表
export function listPrivilege(query) {
  return request({
    url: '/biz/privilege/list',
    method: 'get',
    params: query
  })
}

// 查询特权卡分页
export function pagePrivilege(query) {
  return request({
    url: '/biz/privilege/page',
    method: 'get',
    params: query
  })
}

// 查询特权卡详细
export function getPrivilege(data) {
  return request({
    url: '/biz/privilege/detail',
    method: 'get',
    params: data
  })
}

// 新增特权卡
export function addPrivilege(data) {
  return request({
    url: '/biz/privilege/add',
    method: 'post',
    data: data
  })
}

// 修改特权卡
export function updatePrivilege(data) {
  return request({
    url: '/biz/privilege/edit',
    method: 'post',
    data: data
  })
}

// 删除特权卡
export function delPrivilege(data) {
  return request({
    url: '/biz/privilege/delete',
    method: 'post',
    data: data
  })
}
